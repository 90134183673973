

const databits = [
  {
    title: 'Productos Químicos',
    content:
      'Productos químicos altamente concentrados y amigables con el ambiente. Elaborados bajo éstrictos estándares de calidad e inocuidad. Soluciones químicas para limpieza, desinfección y descontaminación de superficies.',
    
      productName: 'Químicos para limpieza y desinfección de superficies, limpieza y cuidado de superficies diversas',
    
    productImage: '',
    path: '/productos/quimicos'
  },
  {
    title: 'Absorbentes',
    content:
      'Absorbentes fabricados con materia prima importada de primera calidad, bajo procesos controlados que garantizan absorción, suavidad y resistencia. ',
    
      productName: 'Absorbentes Tissue',
    
    productImage: '',
    path: '/productos/absorbentes'
  },
  {
    title: 'Higiene de manos',
    content:
      'Cuidamos su salud como la de los suyos con nuestros productos de alta calidad para el lavado y desinfección de manos. Encuentre gel antibacterial, jabón líquido, alcohol para manos. ',
      
    productName: 'Lavado de manos, cuidado de manos, desinfección de manos. Alcohol antiséptico para manos, Gel antibacterial, Jabón Líquido uso frecuente, Jabón Líquido antibacterial.',
    
    productImage: '',
    path: '/productos/higiene-de-manos'
  },
  {
    title: 'Dispensadores',
    content:
      'Equipos para dispensar papel higiénico, jabón, toallas de manos resistentes y elegantes. Ideales para establecimientos de alto tráfico.  ',
     
      productName: 'Dispensadores',
    
    productImage: '',
    path: '/productos/dispensadores'
  }, 
  
  {
    title: 'Lavandería',
    content:
      'Detergentes y suavizantes desarrollados con fórmulas biodegradables y sin fosfato ideal para el lavado y cuidado textil de uso doméstico, institucional e industrial. 	',
     
    productName: 'detergente líquido, detergente en polvo, suavizante de ropa',
   
    productImage: '',
    path: '/productos/lavanderia'
  },
  {
    title: 'Línea Profesional',
    content:
      'Implementos profesionales de limpieza de alta calidad para asegurar la limpieza adecuada de su entorno profesional o doméstico. Encuentre mopa de barrido, coche escurridor, escurridor de piso.	',
     
    productName: 'mopas, coche escurridor, trapeador, señaletica de pisos mojados, escurridor',
   
    productImage: '',
    path: '/productos/linea-profesional'
  },
  {
    title: 'Seguridad Ocupacional',
    content:
      'Equipos de protección personal para la seguridad física de sus colaboradores. Encuentre mascarillas, guantes de nitrilo.	',
     
    productName: 'mascarrillas, guantes de nitrilo',
   
    productImage: '',
    path: '/productos/seguridad-ocupacional'
  },
];
export default databits;
